@font-face {
  font-family: 'Facundo';
  src: url('Facundo-Light.woff') format('woff'),
       url('Facundo-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Facundo';
  src: url('Facundo-Regular.woff') format('woff'),
       url('Facundo-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Facundo';
  src: url('Facundo-SemiBold.woff') format('woff'),
       url('Facundo-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Facundo';
  src: url('Facundo-Bold.woff') format('woff'),
       url('Facundo-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Facundo';
  src: url('Facundo-Black.woff') format('woff'),
       url('Facundo-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

